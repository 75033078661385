<template>
    <div>
      <div v-if="pageLoaded" class="main-container">
        <tz-sticky :offset-bottom="0" position="top">
          <tz-nav-bar class="no-print" :title="lang.acknowledgement" left-text="" :border="false"  @click-right="closePreview">
            <template #right>
          </template>
          </tz-nav-bar>
        </tz-sticky>
        <div class="form-container">
          <div class="form-wrapper">
            <div>
              <div class="pt-2">
                <div style="text-align: center; padding: 20px">
                  <tz-icon v-if="transaction.status == 'SUCCESSFUL'" name="passed" id="success-icon" size="80px" color="green"></tz-icon>
                  <tz-icon v-else name="cross" id="success-icon" size="80px" color="red"></tz-icon>
                </div>
              </div>
              <div style="text-align: center; font-weight: bolder; color: green;" v-if="showRedirect">
                Redirecting in {{ redirectCounter }}...
              </div>
              <div v-if="transaction.status == 'SUCCESSFUL'">
                <h1 style="text-align: center; color: #990099; margin-top: 10px; margin-bottom: 30px;">
                  {{lang.thankYou}}
                </h1>
                <h4 style="text-align: center; margin-top: 0px; margin-bottom: 30px;">
                  {{lang.yourPaymentOf}} <span style="font-size: 18px; font-weight: bolder; color: #990099;">{{currency(transaction.amount, transaction.currencyCode)}}</span><small>{{transaction.currencyCode}}</small> {{lang.wasSuccessful}}
                </h4>
              </div>
              <div v-else>
                <h1 style="text-align: center; color: #d00; margin-top: 10px; margin-bottom: 30px;">
                  {{lang.failed}}
                </h1>
                <h4 style="text-align: center; margin-top: 0px; margin-bottom: 30px;">
                  {{lang.yourPaymentOf}} <span style="font-size: 18px; font-weight: bolder; color: #990099;">{{currency(transaction.amount, transaction.currencyCode)}}</span><small>{{transaction.currencyCode}}</small> {{lang.wasUnsuccessful}}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 50px">
            <tz-button round block type="primary" native-type="submit" @click="goTo('/invoice/' + invoiceId)" v-if="invoiceId">
              <tz-icon name="wap-home-o" color="#fff"></tz-icon> {{lang.viewInvoice}}
            </tz-button>
            <tz-button round block type="primary" native-type="submit" @click="goTo('/payment-link/' + paymentLinkId)" v-if="paymentLinkId && !showRedirect">
              <tz-icon name="wap-home-o" color="#fff"></tz-icon> {{lang.viewPaymentLink}}
            </tz-button>
        </div>
      </div>
      <div class="main-container" style="padding: 20px" v-else-if="pageLoaded === false">
        <div style="padding-top: 25vh; text-align: center; font-weight: bolder;">
          <div>
            <tz-icon name="info-o" size="80px" color="#f00a"></tz-icon>
          </div>
         <h2>
          {{lang.failedToLoad}}
         </h2>
        </div>
        <div style="padding-top: 100px;">
           <tz-button round block type="primary" native-type="submit" @click="verifyInvoicePayment()">
              <tz-icon name="replay" color="#fff"></tz-icon> {{lang.reload}}
            </tz-button>
        </div>
      </div>

      <!-- payment notification modal -->

      <!-- <tz-dialog v-model:show="show" title="Title" show-cancel-button>
        <img src="https://fms.tranzak.me/tzui/apple-3.jpg" />
      </tz-dialog> -->


    </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed, watch } from "vue";
import { Toast, ImagePreview, Dialog } from 'tzui-vue'
import { getAppId, getLocal, formatCurrency, formatDate, getAppKey } from "../../utils";
import api from './api';
import TzRequireAuth from "@/components/TzRequireAuth";
import {
  payFee,
  updateFee,
  getSpecific,
  getPrevPayment,
} from "@/api/common";

export default {
  name: 'DisplayInvoice',
  components: {
    TzRequireAuth
  },
  data() {
    return {
      redirectCounter: 5,
      showRedirect: false,
      payment: {},
      date: formatDate,
      currency: formatCurrency,
      currencyCode: 'XAF',
      paidViaCash: true,
      paidViaOtherMeans: false,
      showPayNow: false,
      incomeSource: [],
      router: useRouter(),
      actions: [],
      statuses: {
        draft: 1,
        active: 2,
        paid: 3,
        cancelled: 4,
        closed: 5,
      },
      status: [{
            1: 'draft', 2: 'active', 3: 'paid', 4: 'cancelled', 5: 'closed'
        },
        {
            1: '#343a40', 2: '#990099', 3: '#28a745', 4: '#dc3545', 5: '#dc3545'
        }],
      action: 0,
      pageLoaded: null,
      showActions: false,
      showAddPayment: false,
      showMarkAsPaid: false,
      paymentSuccessful: false,
      errors: [],
      items: [],
      lang: {},
      mode: 1, //1 for opened as child component, 2 for opened from browser link
      message: '',
      activeItem: [],
      paymentLink: {},
      appId: getAppId(),
      appInfo:{
        displayName: 'Tranzak Payment Gateway',
        appId: getAppId(),
      },
      baseURL: location.href,
      invoiceId: null,
      paymentLinkId: null,
      transactionId: null,
      requestId: null,
      transaction: {},
    }
  },
  computed:{
    total(){
      return parseInt(this.paymentLink.totalTax) + parseInt(this.paymentLink.subtotal) || 0;
    },
    amountLeft(){
      return parseInt(this.paymentLink.subtotal) + parseInt(this.paymentLink.totalTax) - parseInt(this.paymentLink.totalPaid) || 0;
    }
  },
  mounted () {
    // if(process.env.NODE_ENV != 'development'){
    //   this.baseURL += '/pay';
    // }

    this.invoiceId = this.$route.query.invoiceId;
    this.paymentLinkId = this.$route.query.paymentLinkId;
    this.transactionId = this.$route.query.transactionId;
    this.requestId = this.$route.query.requestId;

    if(!this.requestId || (!this.paymentLinkId && !this.invoiceId)){
      this.$router.replace('/');
      return 0;
    }

    const language = getLocal('app-lang') || 'en';
    this.lang = language.includes('fr')? require('@/assets/languages/fr.json'): require('@/assets/languages/en.json');
    this.currencyCode = getLocal('app-currencyCode') || 'XAF';
    this.verifyInvoicePayment();
  },
  methods: {
    triggerRedirect(link){
      this.showRedirect = true;
      const interval = setInterval(()=>{
        this.redirectCounter -=1;
      },1000);
      setTimeout(()=>{
        clearInterval(interval);
        const query = location.search.replace('?', '');
        const href = link.includes('?')? `${link}&${query}`: `${link}?${query}`;
        window.TzSdk.isTzApp((result) => {
          if(result.data.success){
            return false;
          }
          location.href = href;
        });
      }, 5000)
    },
    goTo(route){
      this.router.push(route);
    },
    closePreview(){
      this.$emit('close', true);
    },
    async verifyInvoicePayment(){
      this.pageLoaded = null;
      let API = api.verifyInvoicePayment;
      if(this.paymentLinkId){
        API = api.verifyPaymentLink
      }
      
      await API(this.requestId).then(res=>{
        console.log(res.data);
        if(res.success){
          this.transaction = res.data.cortexTransaction
          const paymentLink = res.data.paymentLink
          if(paymentLink && paymentLink.returnUrl && this.transaction.status == "SUCCESSFUL"){
            this.triggerRedirect(paymentLink.returnUrl);
          }
          this.pageLoaded = true;
        }
      }).catch(err=>{
        this.pageLoaded = false;
        Dialog.confirm({
          message: this.lang.failedToLoadContent,
          confirmButtonText: this.lang.yes,
          cancelButtonText: this.lang.no
        }).then(res=>{
          this.verifyInvoicePayment();
        })
        console.log(err);
      })
    },
  }
};
</script>
<style lang="less">
  #success-icon{
    margin-top: 100px;
    box-shadow: 1px 1px soli;
  }
@import "../../common/css/vars";
.form-container {
  padding-top: 10px;
  // margin-bottom: 30px;
  // background-color: var(--tz-primary-color);
  position: relative;
  border: 0px !important;
}
.headings {
  text-align: left;
  padding-left: 30px;
  padding-top: 30px;
  .name {
    color: #eee;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .main-heading {
    color: white;
    font-size: 36px;
    font-weight: bold;
    line-height: 1em;
  }
}
.form-header-wrapper {
  height: 150px;
  position: relative;
}
.form-wrapper {
  background-color: white;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 0px 10px;
  // box-shadow: 0px -4px 5px 0px rgba(120, 120, 120, 0.5);
  .paymentLink-row{
    padding: 7.5px 5px
  }
  .paymentLink-display-image{
    margin: 10px 0px;
    padding: 5px;
    border-radius:5px;
    background-color:#efefef88;
    text-align: center;
    .tz-image{
      img{
        max-height: 250px !important;
        border-radius: 4px;
      }
    }
  }
}
.paymentLink-labels{
    color: #0005;
    text-decoration: underline;
    // color: var(--tz-primary-color);
    font-weight: bolder;
  }
.bottom-total{
  padding: 10px 10px;
  background-color: #fffbff;
  text-align: center;
}
.sticky-bottom{
  background-color: #bbb;
  width: 100vw;
}
.paymentLink-download{
  text-align: center;
  padding: 10px 0px;
}
.items-title, .item-detail{
  display: flex;
}
.items-title div:nth-child(1){
  width: 75%;
}
.items-title div:nth-child(2){
  white-space: nowrap;
}
.item-detail div:nth-child(2){
  padding-left: 10px;
}
.sub-menu{
  padding: 10px 7.5px;
  text-align: center;
}
.paymentLink-actions{
  margin-bottom: 7.5px;
}
.w-100{
  width: 100%;
}
.pay-switches{
  text-align: left;
  padding: 10px 15px;

  display: flex;
  align-items: center;
}
.pay-switches >div:nth-child(2){
  width:30%;
  text-align: right;
}
.pay-switches > div:first-child{
  width: 70%;
}
.paymentLink-display-items{
  padding: 5px;
  border: 1px solid #ccc6;
  border-radius: 10px;
  margin-top: 10px;
}
small{
  font-size: 11px
}
.printable table{
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
  td{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  th{
    padding: 5px;
    border: 1px 0px 1px 0px solid #ccc;
  }
}
</style>
<style lang="less">
  .printable{
    display: none;
  }
  .no-print{
      display: block;
    }
  @media print{
    html,body{
      width: 100% !important;
      max-width: 100%;
      flex-grow: 1;
    }
    .printable{
      display: block !important;
    }
    .no-print{
      display: none !important;
    }
    .d-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .w-25 {
      width: 25% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-100 {
      width: 100% !important;
    }
    .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .col-6:nth-child(odd) {
        text-align: right;
      }
    }
    .col {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .paymentLink-status{
      font-weight: bolder;
      color: #222 !important;
    }
  }
  .tz-popover__content{
    width: 160px!important;
  }
  .tz-popover__content .tz-popover__action .tz-popover__action-text{
    white-space: nowrap !important;
    padding: 5px !important;
}
.text-right{
  text-align: right !important;
}
.invoice-status{
  font-size: 22px;
  font-weight: bolder;
  color: #222 !important;
}
</style>