export const baseURL = process.env.NODE_ENV == 'production' ? 'https://api.tranzak.me' : 'https://staging-api.tranzak.me' //'http://api.tranzak.me'

export const chatBaseUrl = `${baseURL}/af028/v1`;

export const chatBucketUrl = 'https://staging-api.tranzak.me/cb068/v1';

export const chatSocketUrl = 'https://im.tranzak.me';

export default {
  baseURL: process.env.NODE_ENV == 'production' ? 'https://api.tranzak.me' : 'https://staging-api.tranzak.me',
  localUrl:'http://localhost:3020'
}
