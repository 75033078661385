import request from '@/utils/axios'
import  urls  from '../../utils/urls.js';
import { getPlatformId } from '../../utils/index';

const baseUrl = urls.baseURL;
// const msURL = urls.localUrl; //@@TODO CHANGE TO REMOTE when pushing
// const msURL =   'http://localhost:3020/v1/'; // remote url
const msURL = baseUrl + "/tz988/v1/";
// const url = "https://staging-api.tranzak.me/tz988/v1/invoice/";


export default {
    viewTransaction: (params = '') => request.get(baseUrl + '/fapi/guest-flow/view-transaction?_id=' + params),
    verifyInvoicePayment: (params = '') => request.post(msURL + 'invoice/verify-payment/'+params),
    verifyPaymentLink: (params = '') => request.post(msURL + 'payment-link/verify-payment/'+params)
}