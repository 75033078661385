<template>
 <div class="tz-require-auth-wrapper">
   <div v-if="!isUserLogin" class="tz-auth-overlay" @click="getLoginInfo()"></div>
   <div class="tz-auth-child">
    <slot></slot>
   </div>
 </div>
</template>

<script>
import { getAppId, setLocal, getLocal, setToken,getToken, setUfId, setAppId, setScopeId } from "../utils/index";

export default {
  data () {
    return {
      isUserLogin: getToken()
    }
  },
  methods: {
    getLoginInfo() {
      console.log("get login info=====>>>>>>>>>>>>========= ");

      window.TzSdk.getLoginInfo(
              null,
              async (result={}) => {
                console.log("result===getLoginInfo>>>>>>>>=====---",result);
                if(result && result.data) {
                  const appId = getAppId();
                    this.isUserLogin = true;
                  setToken(appId, result.data);
                }
              }
            );
    }
  }
}
</script>

<style lang="less" scoped>
  .tz-require-auth-wrapper {
    position: relative;

   .tz-auth-overlay {
      z-index: 1000;
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
    }

    .tz-auth-child {
      position: relative;
      z-index: 100;
    }
  }
</style>
